<template>
    <div style="background:#eff1f2">
        

        <img class="top-img" :src="data.model.img_org"/>
        <div class="goods-info">
            <div class="goods-title">
                <div class="goods-name">{{ data.model.product_title }}</div>
            </div>
            <div class="goods-desc">{{ data.model.summary }}</div>
        </div>
        <div class="goods-detail-page">
            <div class="desc-content">
              <div class="desc-content__title">
              <img class="img" src="../../assets/img/bx/rec-left.png" />
              <span class="desc-content__title--text">{{search.orderCode}}</span>
              <img class="img" src="../../assets/img/bx/rec-right.png" />
              </div>
            </div>
        </div>

        <div class="goods-detail-page" v-if="data.fxFlag">
            <div class="desc-content">
              <div class="desc-content__title">
              <!-- <img class="img" src="../../assets/img/bx/rec-left.png" /> -->
              <span class="desc-content__title--text">立刻分享给他领取</span>
              <!-- <img class="img" src="../../assets/img/bx/rec-right.png" /> -->
              </div>
            </div>
        </div>

        <div class="share-bottom" v-if="data.lqFlag">
            <div class="share-bottom-con">
                <div style="height: 2rem; text-align: center;">{{ data.order.phone }}</div>
                <div class="share-bottom-phone">
                    手机号：<input v-model="data.phone" class="share-bottom-phone-input"/>
                </div>
                <div @click="clickSend()" class="share-bottom-btn" style="background:colorbtn2">
                    立即领取
                </div>
            </div>
        </div>

        <div class="share-bottom" v-if="data.backFlag">
            <div class="share-bottom-con">
                <!-- <div style="height: 2rem; text-align: center;">{{ data.order.phone }}</div> -->
                
                <div @click="clickIndex()" class="share-bottom-btn" style="background:colorbtn2">
                    回首页
                </div>
            </div>
        </div>
    
        <!-- <div class="shop-btn-div">
            <div @click="data.showPhone=true" class="shop-btn2">
                <div>
                  立即赠送
                </div>
            </div>
        </div> -->
        
        <div class="service">
            <div class="service-title2">{{ getTitle1() }}</div>
            <div class="service-title3">{{ getTitle2() }}</div>
        </div>
        <div style="height:2rem"></div>
    </div>
    <div class="tip" v-if="data.zfFlag">
        <img class="tip-img" src="../../assets/img/bx/tips.png"/>
    </div>
    
      <KfCard ref="kfcomp"></KfCard>
      <ShareCard ref="sharecomp"></ShareCard>
    </template>
    
    <script setup>
    import {} from 'vant';
    import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
    import Menu from '../../components/Menu.vue'
    import {useRoute,useRouter} from 'vue-router'
    import http from '../../common/bxhttp.js';
    import {isJSON,isNull,showMessage,nullStr,disLabel,getTitle1,getTitle2} from '../../common/utils.js'
    // import KfCard from '../../components/KfCard.vue'
    import ShareCard from '../../components/ShareCard.vue'
    import wx from 'weixin-js-sdk';

   

    const kfcomp = ref(null)
    const sharecomp=ref(null)
    
    function showCacl(){
        router.push({ path: '/calcback'} )
    }
    
    function showShare(){
      sharecomp.value.showShare({type:"product",model:data.search});
    }
    
    function showKf(){
      kfcomp.value.showKf();
    }
    
    let route=useRoute()
    let router=useRouter()
    
    let data=reactive({
        search:{
            productCode:route.query.productCode
        },
        model:{
            
        },
        order:{

        },
        type:nullStr(localStorage.getItem("type")),
        fxFlag:false,
        lqFlag:false,
        zfFlag:false,
        phone:""
    })
    let search=reactive({
        productCode:route.query.productCode,
        orderCode:route.query.orderCode
    })

    localStorage.setItem("shareProductCode",route.query.productCode)
    localStorage.setItem("shareOrderCode",route.query.orderCode)
    
    function clickIndex(){
        router.push({ path: '/bxindex' })
    }

    function clickSend(){
      http.post("zbx/order/orderreceive",{
        "order_code":search.orderCode,
        "receive_phone":data.phone,
        "org_code":data.model.org_code
      }).then((res)=>{
            if(res.errcode=="0"){
              //01 未匹配、03 匹配中、05 已匹配 07 专家匹配中 09 专家匹配完成
              showMessage("领取成功");
              router.push({ path: '/bxindex' })
            }else{
              showMessage(res.errmsg);
            }
      })
    }
    
    function searchOrder(){
        http.post("order/web/order/orderreceivephone",{orderCode:search.orderCode}).then((res)=>{
            if(res.errcode=="0"){
                //01 未匹配、03 匹配中、05 已匹配 07 专家匹配中 09 专家匹配完成
                data.order=res.detail;
                refreshPageStatus();
            }else{
              showMessage(res.errmsg);
            }
        })
    }

    function refreshPageStatus(){
        // var type=this.data.type;
        // var order=this.data.order;
        if(data.type=="I"){
            //保险用户
            if(data.order.status=="0"){
                data.fxFlag=true;
                data.zfFlag=true;
            }else{
                data.backFlag=true;
            }
        }else if(data.type=="U"){
            //普通用户
            if(data.order.status=="0"){
                data.lqFlag=true;
            }else{
                data.backFlag=true;
            }
        }
        localStorage.removeItem("shareProductCode");
        localStorage.removeItem("shareOrderCode");
    }

    
    function searchDetail(){
        http.post("order/web/product/productdetail",data.search).then((res)=>{
            if(res.errcode=="0"){
                //01 未匹配、03 匹配中、05 已匹配 07 专家匹配中 09 专家匹配完成
                data.model=res.detail;
            }else{
              showMessage(res.errmsg);
            }
      })
    }
    searchDetail()
    searchOrder()

    function sendWx(){
      http.post("zbx/web/wx/share",{
        "nonce":location.href
      }).then((res)=>{
            if(res.errcode=="0"){
                let det=res.detail;
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: det.appId, // 必填，公众号的唯一标识
                    timestamp: det.timestamp, // 必填，生成签名的时间戳
                    nonceStr: det.nonceStr, // 必填，生成签名的随机串
                    signature: det.signature,// 必填，签名
                    jsApiList: ["updateAppMessageShareData"] // 必填，需要使用的JS接口列表
                });
              
            }else{
              showMessage("=="+res.errmsg);
            }
      })
    }

   

    wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({ 
            title: data.model.product_title, // 分享标题
            desc: data.model.summary, // 分享描述
            link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: data.model.img_org, // 分享图标
            success: function () {
            // 设置成功
            }
        })
    });
    wx.error(function(res){
        // alert(JSON.stringify(res))
    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    });


    sendWx();

    
    
    </script>
    
    <style scoped lang="scss">
    .tip{
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        position: fixed;
        z-index: 1000;
        background-color:rgba(0,0,0,0.4);
        filter: alpha(opacity=40)
    }
    .tip-img{
        width: 72%;
        margin-left: 22%;
    }
    .top-img {
        width: 100%;
    }
    .goods-info{
        background-color: #fff;
        padding: 1rem 0 1rem 1rem;
    }
    .goods-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-top: 1rem;
    }
    .goods-name {
        width: 100%;
        font-weight: 500;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-size: 1.1rem;
        word-break: break-all;
        color: #333333;
    }


    .goods-detail-page .desc-content {
    margin-top: 1rem;
    background-color: #fff;
    padding-bottom: 120rpx;
    }

    .goods-detail-page .desc-content__title {
    font-size: 1rem;
    line-height: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem;
    }

    .goods-detail-page .desc-content__title .img {
    width: 100px;
    height: 5px;
    }

    .goods-detail-page .desc-content__title--text {
    font-size: 1rem;
    margin: 0 0.8rem;
    font-weight: 600;
    }

    .goods-detail-page .desc-content__img {
    width: 100%;
    height: auto;
    }
    .img{
        width: 206rpx;
        height: 10rpx;
    }
    .detail-img{
        width: 100%;
    }
    .goods-bottom{
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        padding-bottom: env(safe-area-inset-bottom);
    }
    .goods-bottom-con{
        padding:16rpx;
        box-sizing: border-box;
        margin: 0;
        border: 0;
        outline: 0;
        display: flex;
        justify-content: space-between;
    }

    .bottom-nav{

    }
    .bottom-nav-con{
        padding-top: 0.2rem;
    }
    .bottom-nav-title{
        margin-top: 0.1rem;
        font-size: 0.8rem;
    }
    .bottom-nav-img-view{

    }
    .bottom-nav-img{
        width: 1.5rem;
        height: 1.5rem;
        margin: auto;
        display: flex;
    }
    .bottom-send{
        background-color: #fa4126;
        color:#fff;
        flex:1;
        font-size: 1.2rem;
        margin-left: 1rem;
        height: 3rem;
        border-radius: 40rpx;
        line-height: 3rem;
        text-align: center;
    }
    .grey-view{
        z-index: 99998;
        position: fixed;
        left: 0;
        bottom: 0;
        right:0;
        top:0;
        background-color: rgb(0, 0, 0,0.6);
    }
    .share-bottom{
        z-index: 99999;
        height: 10rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        // position: fixed;
        // left: 0;
        // right:0;
        // bottom: 0;
        width: 100%;
        background-color: #fff;
    }
    .share-bottom-con{
        padding: 1rem;
    }
    .share-bottom-phone{
        border: 1px solid #ddd;
        border-radius: 1rem;
        display: flex;
        height: 3rem;
        line-height: 3rem;
        padding-left: 1rem;
        justify-content: center;
        align-items: center;
    }

    .share-bottom-btn{
        margin-top: 1rem;
        border-radius: 1rem;
        background-color: #fa4126;
        color:#fff;
        flex:1;
        font-size: 1.2rem;
        height: 3rem;
        border-radius: 40rpx;
        line-height: 3rem;
        text-align: center;
    }


    .shop-btn-div{
        position: fixed;
        bottom: 0px;
        left:0rem;
        right:0rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background: #fff;
        display:flex;
        .ml{
            margin-left:2rem;
        }
        .shop-btn1{
            flex:1;
            // background:#3b6eff;
            color:#3b6eff;
            border:1px solid #3b6eff;
            border-radius: 1.2rem;
            justify-content: center;
            align-items: center;
            display: flex;
            .divl{
                background: #3b6eff;
                height: 100%;
                width: 3rem;
                border-top-left-radius: 1.2rem;
                border-bottom-left-radius: 1.2rem;
                text-align: center;
                display:flex;
                div{
                    margin:auto;
                }
            }
            img{
                width:1.6rem;
                vertical-align: middle;
            }
            .shop-btn-name{
                flex:1;
                margin-left:1rem;
            }
        }
        .shop-btn2{
            flex:1;
            justify-content: center;
            background:#C90000;
            color:#fff;
            border-radius: 1.2rem;
            padding:0.5rem;
            padding-left: 0.5rem;
            padding-right:0.5rem;
            text-align: center;
            display: flex;
            img{
                width:1rem;
                vertical-align: middle;
            }
            .shop-btn-name{
                margin-left:0.4rem;
            }
        }
    }
    .shop-btn{
        background:#3b6eff;
        color:#fff;
        border-radius: 1rem;
        padding:0.3rem;
        padding-left: 0.5rem;
        padding-right:0.5rem;
        text-align: center;
        display: flex;
        img{
            width:1rem;
            vertical-align: middle;
        }
        .shop-btn-name{
            margin-left:0.4rem;
        }
    }
    .cvip13{
        font-size:0.8rem;
        display: flex;
        justify-content: center;
        margin-left: 0rem;
        margin-right: 0rem;
        .s1{
            width: 1rem;
            height: 0.1rem;
            margin-top: 0.6rem;
            background: linear-gradient(90deg,#febe9a,#f95706);
        }
        .s2{
            margin-left: 0.1rem;
            margin-right: 0.1rem;
        }
        .s3{
            width: 1rem;
            height: 0.1rem;
            margin-top: 0.6rem;
            background: linear-gradient(90deg,#f95706,#febe9a);
        }
        color: #5617a6;
    }
    .pro-detail-con{
        margin:1rem;
        .pro-tg-con{
            padding:1rem;
            margin-top:1rem;
            background: #fff;
            border-radius: 1rem;
            .pro-tg-con-flow-title{
                margin-top: 0.3rem;
                div{
                    background: #fff8f3;
                    margin-top:0.5rem;
                    color:#f86402;
                    padding:0.2rem;
                    padding-right:0.5rem;
                    padding-left:0.5rem;
                    display: inline;
                    
                }
            }
            .pro-tg-con-flow-desc{
                a{
                    color:#3a6ff8;
                }
            }
            .pro-tg-con-desc{
                margin-top:1rem;
                white-space: pre-line;
            }
            .pro-tg-con-div{
                display: flex;
                margin-top:1rem;
                .pro-tg-con-item{
                    flex:1;
                    background: #fff8f3;
                    border-radius: 0.5rem;
                    padding:0.5rem;
                    height:8rem;
                    &.ml{
                        margin-left: 0.5rem;;
                    }
                    img{
                        width:90%;
                    }
                    .pro-tg-con-item-desc{
                        font-size: 0.8rem;
                        margin-top:0.5rem;
                        a{
                            color:#c71a1a;
                        }
                    }
                }
            }
        }
    
    
        .pro-dt-con{
            margin-top:1rem;
            background: #fff;
            border-radius: 1rem;
            .pro-dt-item-sp{
                background: #e4e4e4;
                height:1px;
                margin-left: 2rem;
                margin-right: 1rem;
            }
            .pro-dt-item{
                padding:1rem;
                display: flex;
                .pro-dt-item-news{
                    width:0.6rem;
                    height:0.6rem;
                    margin:0.1rem;
                    border-radius: 50%;
                    background: #4d9dd0;
                }
                
                .pro-dt-item-desc{
                    margin-left: 0.5rem;
                    .pro-dt-item-time{
                        color:#b9bbbb;
                        font-size:0.8rem;
                    }
                    .pro-dt-item-name{
                        margin-top: 0.3rem;
                    }
                }
            }
        }
        .pro-detail-div-title{
            display:flex;
            justify-items: center;
            .title-img{
                // width:2rem;
                // height:auto;
            }
            .title{
                line-height: 2rem;
                margin-left: 1rem;
                font-size:1.1rem;
                color:#4d9dd0;
                &.bt{
                    flex: 1;
                    border-bottom: 1px solid #e4e4e4;
                }
            }
            .right-desc{
                margin-top:0.3rem;
                color:#fff;
                font-size: 0.8rem;
                background: #4d9dd0;
                border-radius: 0.3rem;
                padding-left: 0.3rem;
                padding-top: 0.1rem;
                padding-bottom: 0.1rem;
                padding-right: 0.3rem;
            }
        }
    }
    .div-pro{
        position: absolute;
        left: 1rem;
        right: 1rem;
        top: 1rem;
        background: #fff;
        border-radius: 1rem;
        display: flex;
        flex-direction:column;
        .match-product-item{
            padding:1rem;
            background: #fff;
            border-radius: 1rem;
    
            .item-news{
                border: 30px solid #c90100;
                height: 0;
                width: 0;
                border-right-color: transparent;
                border-bottom-color: transparent;
                position: absolute;
                margin-left: -1rem;
                margin-top: -1rem;
                border-top-left-radius: 1rem;
                .item-news-title{
                    margin-left: -1.3rem;
                    margin-top: -1.3rem;
                    color: #fff;
                }
            }
    
            .item-hr{
                height: 1px;
                background: #f3f3f3;
                margin-top:0.3rem;
                margin-bottom:0.3rem;
            }
            .item-label{
                margin-top:1rem;
                margin-left:2rem;
                margin-right:2rem;
                font-size:0.9rem;
                .item-label-div{
                    margin-bottom:0.5rem;
                    display:flex;
                    img{
                        width:1.2rem;
                        height:1.2rem;
                    }
                    div{
                        margin-left:0.4rem;
                    }
                }
            }
            .item-cacl{
                display:flex;
                margin-top:1rem;
                .item-cacl1{
                    padding-top:0.5rem;
                    padding-bottom: 0.5rem;
                    background: #eff1f2;
                    border-radius: 0.3rem;
                    text-align: center;
                    flex:1;
                    img{
                        height:1.2rem;
                    }
                    div{
                        font-size: 0.8rem;
                        color:#0c64fb;
                        margin-top:0.2rem;
                    }
                }
            }
            .item-top{
                display:flex;
                .item-top-img{
                    width:4rem;
                    height:4rem;
                }
                .item-top-desc{
                    margin-left:1rem;
                    flex:1;
                    .item-back{
                        margin-top:0.5rem;
                        font-size:0.9rem;
                        width:100%;
                        .item-back-desc{
                            display: inline-block;
                            color:#3D6FFF;
                            border:1px solid #3D6FFF;
                            padding-left:0.3rem;
                            padding-right:0.3rem;
                            margin-right:0.5rem;
                            border-radius:0.3rem;
                            margin-bottom: 0.3rem;
                        }
                    }
        
                    .item-prop{
                        color:#949999;
                        font-size:0.9rem;
                        width:100%;
                        .item-prop-desc{
                            margin-right:1rem;
                            margin-top:0.5rem;
                            .red{
                                margin-left:0.2rem;
                                color:#C90000;
                            }
                            .black{
                                margin-left:0.2rem;
                                color:#0A1E3C;
                            }
                        }
                    }
                    .item-name{
                        display:flex;
                        .item-name-name{
                            font-weight:500;
                        }
                        .item-name-desc{
                            margin-left:auto;
                            font-size:0.8rem;
                            margin-left:auto;
                            color:#3D6FFF;
                        }
                    }
                    .item-warn{
                        background: #fcefe5;
                        color: #d21616;
                        padding-left: 0.3rem;
                        padding-right: 0.3rem;
                        border-radius: 0.2rem;
                        display: inline;
                    }
                }
            }
        }
    }
    .banner{
      width:100%;
      height:auto;
    }
    
    .bottom-btn{
      margin:1rem;
      display:flex;
    }
    .service{
    
      .service-title2{
        text-align: center;
        color: #848E9D;
        line-height: 2rem;
        align-items: center;
        font-weight: 500;
        margin-left: 1rem;
        font-size: 1.1rem;
        margin-top: 4rem;
      }
      .service-title3{
        text-align: center;
        color: #949999;
        line-height: 2rem;
        align-items: center;
        margin-left: 1rem;
        font-size: 0.9rem;
        margin-bottom: 2rem;
      }
    }
    
    .pop-city{
      height: 57%;
      background: #fff;
      position: fixed;
      bottom: 0px;
      left: 0.5rem;
      right: 0.5rem;
      z-index: 99999;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    
      .pop-ewm{
        text-align: center;
        margin-top: 1rem;
        .pop-ewm-img{
          width:8rem;
        }
        .pop-ewm-desc{
          display:flex;
          justify-content:center;
          margin-top:0.5rem;
          img{
            margin-top:0.1rem;
            width:1rem;
            height:1rem;
          }
          div{
            margin-left:0.3rem;
            color:#3a6df9;
            font-size: 0.8rem;
          }
        }
      }
    
      .pop-steps{
        display: flex;
        .pop-steps-con{
          display:flex;
          .pop-steps-con-no{
            color:#3d6eff;
            font-size:3rem;
          }
          .pop-steps-con-name{
            font-size:0.8rem;
            margin-left:0.5rem;
            .mt{
              margin-top: 0.7rem;
            }
          }
        }
        .pop-steps-con-sp{
          flex:1;
          margin-top: 0.7rem;
          text-align: center;
        }
      }
    
      .pop-con{
        margin-left: 1rem;
        margin-right: 1rem;
    
        .mt{
          margin-top:0.5rem;
        }
        .pop-rel{
          position: relative;
          .pop-sel{
            position: absolute;
            border: 1px solid #949999;
            border-radius: 2rem;
            padding-left: 1rem;
            padding-right: 1rem;
            background: #fff;
            z-index: 999;
            right: 0px;
            left: 0px;
            .pop-sel-div{
              display: flex;
              height:2.5rem;
              justify-content: center;
              &.bot{
                border-bottom: 1px solid #949999;
              }
              .pop-sel-div-rad{
                width:1rem;
                height:1rem;
                margin-top: 0.75rem;
              }
              .pop-sel-div-lab{
                line-height:2.5rem;
                margin-left: 1rem;;
              }
            }
          }
        }
        .field-div{
          border: 1px solid #949999;
          border-radius: 2rem;
          padding-left: 1rem;
          display: flex;
          align-items:center;
          .field-sp{
            width:1px;
            background: #ececec;
            height: 1rem;
            margin-left:0.8rem;
          }
          .field-div-label{
            margin:0.8rem;
            color:#afb4bd;
          }
          .field-unit-sub{
            width: 8rem;
            text-align: center;
            height: 2.8rem;
            line-height: 2.8rem;
            border-top-right-radius: 2rem;
            border-bottom-right-radius: 2rem;
            color:white;
            display: flex;
            .field-sp-sub{
              width:1px;
              background: #ececec;
              height: 1rem;
              margin-top:0.8rem;
            }
            .dt2-sj-b{
                margin-top: 1.3rem;
                width: 100%;
                text-align: center;
                justify-content: center;
                justify-items: center;
                display: flex;
            }
            .dt2-sj{
                width: 0px;
                height: 0px;
                border: 0.4rem solid transparent;
                border-top-color: #949999;
            }
          }
          .field-unit{
            background: #929898;
            width: 8rem;
            text-align: center;
            height: 2.8rem;
            line-height: 2.8rem;
            border-top-right-radius: 2rem;
            border-bottom-right-radius: 2rem;
            color:white;
          }
          img{
            width: 1rem;
            height: 1.3rem;
            vertical-align: middle;
          }
        }
      }
    
      .fa-chk{
        img{
          width:1rem;
        }
      }
    
      .fa-div-radio{
        margin-top:1rem;
        padding-left:1rem;
        display: flex;
        align-items: center;
        .fa-radio{
          width:0.8rem;
          height:0.8rem;
          border: 1px solid #ddd;
          border-radius: 50%;
          .fa-sel{
            width:0.6rem;
            height:0.6rem;
            margin:0.1rem;
            border-radius: 50%;
            background: #4372f6;
          }
        }
        .fa-label{
          font-size: 0.9rem;
          margin-left: 0.5rem;
          color:#0A1E3C;
        }
        .fa-label-xy{
          font-size: 0.7rem;
          margin-left: 0.5rem;
          color:#afb4bd;
          a{
            color:#3D6FFF;
          }
        }
    
         
      }
      
      .pop-close{
        padding-left: 0.5rem;
        font-size:1.8rem;
      }
      .pop-wtitle{
        margin-left: 3rem;
        margin-right: 3rem;
        .pop-title{
          font-size: 1rem;
          font-weight: bold;
        }
        .pop-sp{
          margin-top:0.5rem;
          margin-bottom:0.5rem;
          height: 1px;
          background: #ebebeb;
        }
        .pop-title-sub{
          color:#949999;
          margin-bottom:0rem;
          font-size: 0.9rem;
        }
        
        .pop-title-label{
          margin-bottom:1rem;
          .pop-title-label-city{
            border: 1px solid #ebebeb;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            border-radius: 1rem;
            font-size: 0.8rem;
            margin-right: 0.8rem;
            float: left;
            margin-top: 0.5rem;
          }
          .clear{
            clear: both;
          }
        }
      }
      .pop-sph{
        height: 0.3rem;
        background: #ebebeb;
      }
      .pop-picker{
        
      }
      .pop-btn{
        margin:1rem;
      }
    }
    


    .share-bottom{
        z-index: 99999;
        height: 10rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        // position: fixed;
        // left: 0;
        // right:0;
        // bottom: 0;
        width: 100%;
        background-color: #fff;
    }
    .share-bottom-con{
        padding: 1rem;
    }
    .share-bottom-phone{
        border: 1px solid #ddd;
        border-radius: 1rem;
        display: flex;
        height: 3rem;
        line-height: 3rem;
        padding-left: 1rem;
        justify-content: center;
        align-items: center;
    }
    .share-bottom-phone-input{
        flex:1;
        border:0px;
        border-radius: 1rem;
        height: 2.4rem;
    }

    .share-bottom-btn{
        margin-top: 1rem;
        border-radius: 1rem;
        background-color: #fa4126;
        color:#fff;
        flex:1;
        font-size: 1.2rem;
        height: 3rem;
        border-radius: 40rpx;
        line-height: 3rem;
        text-align: center;
    }


    .shop-btn-div{
        position: fixed;
        bottom: 0px;
        left:0rem;
        right:0rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background: #fff;
        display:flex;
        .ml{
            margin-left:2rem;
        }
        .shop-btn1{
            flex:1;
            // background:#3b6eff;
            color:#3b6eff;
            border:1px solid #3b6eff;
            border-radius: 1.2rem;
            justify-content: center;
            align-items: center;
            display: flex;
            .divl{
                background: #3b6eff;
                height: 100%;
                width: 3rem;
                border-top-left-radius: 1.2rem;
                border-bottom-left-radius: 1.2rem;
                text-align: center;
                display:flex;
                div{
                    margin:auto;
                }
            }
            img{
                width:1.6rem;
                vertical-align: middle;
            }
            .shop-btn-name{
                flex:1;
                margin-left:1rem;
            }
        }
        .shop-btn2{
            flex:1;
            justify-content: center;
            background:#C90000;
            color:#fff;
            border-radius: 1.2rem;
            padding:0.5rem;
            padding-left: 0.5rem;
            padding-right:0.5rem;
            text-align: center;
            display: flex;
            img{
                width:1rem;
                vertical-align: middle;
            }
            .shop-btn-name{
                margin-left:0.4rem;
            }
        }
    }
    .shop-btn{
        background:#3b6eff;
        color:#fff;
        border-radius: 1rem;
        padding:0.3rem;
        padding-left: 0.5rem;
        padding-right:0.5rem;
        text-align: center;
        display: flex;
        img{
            width:1rem;
            vertical-align: middle;
        }
        .shop-btn-name{
            margin-left:0.4rem;
        }
    }
    .cvip13{
        font-size:0.8rem;
        display: flex;
        justify-content: center;
        margin-left: 0rem;
        margin-right: 0rem;
        .s1{
            width: 1rem;
            height: 0.1rem;
            margin-top: 0.6rem;
            background: linear-gradient(90deg,#febe9a,#f95706);
        }
        .s2{
            margin-left: 0.1rem;
            margin-right: 0.1rem;
        }
        .s3{
            width: 1rem;
            height: 0.1rem;
            margin-top: 0.6rem;
            background: linear-gradient(90deg,#f95706,#febe9a);
        }
        color: #5617a6;
    }
    </style>